import { styled } from '@compiled/react';

import { N200, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledMainDiv = styled.div<{ isTitleCenterAligned?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes show': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%': {
			opacity: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'100%': {
			opacity: 1,
		},
	},
	fontSize: '12px',
	color: token('color.text.subtle', N200),
	lineHeight: 1.5,
	/* update next/packages/content-title/src/ContentTitlePlaceholder.js when this changes */
	margin: `${token('space.250', '20px')} 0 ${token('space.250', '20px')} 0`,
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textAlign: (p) => (p.isTitleCenterAligned ? 'center' : 'inherit'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LivePagesStyledMainDiv = styled(StyledMainDiv)({
	alignItems: 'center',
	margin: token('space.0', '0'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LivePagesWithNewBylineStyledMainDiv = styled(StyledMainDiv)({
	alignItems: 'center',
	margin: token('space.0', '0'),
	flexGrow: 1,
	zIndex: '100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledAvatars = styled.div<{ isTitleCenterAligned?: boolean }>({
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (p) => (p.isTitleCenterAligned ? '0px 7px 0px 0px' : '3px 0px 0px 0px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BylineAppsTextDiv = styled.div({
	margin: `${token('space.025', '2px')} 0 0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledTextDiv = styled.div({
	margin: `0 0 0 ${token('space.100', '8px')}`,
	/* IE11 */
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		textDecoration: 'none',
		color: token('color.link', N200),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a:focus, a:hover, a:visited, a:active': {
		color: token('color.link.pressed', N200),
		textDecoration: 'underline',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledTextDivForNewByline = styled(StyledTextDiv)({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledAuthorDiv = styled.div<{ isTitleCenterAligned?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (p) => (p.isTitleCenterAligned ? 'flex' : 'block'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (p) => (p.isTitleCenterAligned ? 'center' : 'initial'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: (p) => (p.isTitleCenterAligned ? 'center' : 'initial'),
	/* need to specifically override 'a' T_T */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'a:first-of-type': {
		textDecoration: 'none',
		color: token('color.link', N200),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a:focus, a:hover, a:visited, a:active': {
		color: token('color.link.pressed', N200),
		textDecoration: 'underline',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledNameSkeleton = styled.div({
	display: 'inline-block',
	background: token('color.skeleton', N40),
	width: '100px',
	height: '14px',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommentCountContainer = styled.a({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& :before': {
		content: '•',
		display: 'inline-block',
		color: token('color.background.accent.gray.subtle', 'grey'),
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
		padding: '0 7px 0 5px',
		textDecoration: 'none',
		/* for IE <= 11 */
	},
});
